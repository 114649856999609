/* eslint-disable no-underscore-dangle */
import React from 'react'
import { graphql } from 'gatsby'
//
import {
  ContentfulBasicPage,
  ContentfulSettings,
  ContentfulBaristasBlock,
  ContentfulContentBannerNewsletter,
  ContentfulContentBannerPromo,
  ContentfulContentBannerTextAndImages,
  ContentfulContentCarousel,
  ContentfulInstagramBlock,
  ContentfulListingBlock,
  ContentfulMultipleContentBlock,
  ContentfulStackedBlocks,
  ContentfulTextBlock,
  ContentfulTip,
  ContentfulPaperformBlock,
  ContentfulEventCarousel,
  Site
} from '~generated/gatsby.types'
import { MainLayout } from '~layout'
import {
  PrimaryHeroCarousel,
  SecondaryHero,
  TertiaryHero,
  EmptyHero,
  BaristasBlock,
  ContentBannerNewsletter,
  ContentBannerPromo,
  BannerTextAndImages,
  InstagramBlock,
  ListingBlock,
  MultipleContentBlock,
  StackedBlocks,
  TextBlock,
  ContentCarousel,
  VotingBlock,
  Tip,
  PaperformBlock,
  IframeBlock,
  EventCarousel
} from '~components/page-components'
import { buildPageMetaTags } from '~utils'

type PageContextType = {
  id: number
  node_locale: string
}

type PageQuery = {
  site: Site
  contentfulBasicPage: ContentfulBasicPage
  contentfulSettings: ContentfulSettings
}

type PageProps = {
  data: PageQuery
  pageContext: PageContextType
}

type SectionProps =
  | ContentfulBaristasBlock
  | ContentfulContentBannerNewsletter
  | ContentfulContentBannerPromo
  | ContentfulContentBannerTextAndImages
  | ContentfulContentCarousel
  | ContentfulInstagramBlock
  | ContentfulListingBlock
  | ContentfulMultipleContentBlock
  | ContentfulStackedBlocks
  | ContentfulTextBlock
  | ContentfulTip
  | ContentfulPaperformBlock
  | ContentfulEventCarousel
  | null

const heroComponents: any = {
  ContentfulPrimaryHeroCarousel: PrimaryHeroCarousel,
  ContentfulSecondaryHero: SecondaryHero,
  ContentfulTertiaryHero: TertiaryHero,
  EmptyHero
}

const bodyComponents: any = {
  ContentfulBaristasBlock: BaristasBlock,
  ContentfulContentBannerNewsletter: ContentBannerNewsletter,
  ContentfulContentBannerPromo: ContentBannerPromo,
  ContentfulContentBannerTextAndImages: BannerTextAndImages,
  ContentfulContentCarousel: ContentCarousel,
  ContentfulInstagramBlock: InstagramBlock,
  ContentfulListingBlock: ListingBlock,
  ContentfulMultipleContentBlock: MultipleContentBlock,
  ContentfulStackedBlocks: StackedBlocks,
  ContentfulTextBlock: TextBlock,
  ContentfulVotingBlock: VotingBlock,
  ContentfulTip: Tip,
  ContentfulPaperformBlock: PaperformBlock,
  ContentfulIframeBlock: IframeBlock,
  ContentfulEventCarousel: EventCarousel
}

type getBodySectionProps = {
  section: SectionProps
  contentfulSettings: ContentfulSettings
}

const getBodySection = ({ section, contentfulSettings }: getBodySectionProps) => {
  if (!section || !section.__typename || !section.id) {
    return null
  }

  const Section = bodyComponents[section?.__typename]

  return <Section key={section.id} {...section} contentfulSettings={contentfulSettings} />
}

const BasicPage = ({ data }: PageProps) => {
  const { site, contentfulBasicPage, contentfulSettings } = data
  const { heroSection, bodySections } = contentfulBasicPage

  const HeroSection = heroComponents[heroSection?.__typename || 'EmptyHero']

  return (
    <MainLayout metaTags={buildPageMetaTags(contentfulBasicPage, site)} contentfulSettings={contentfulSettings}>
      <HeroSection {...heroSection} />
      {bodySections && bodySections.map(section => getBodySection({ section, contentfulSettings }))}
    </MainLayout>
  )
}

export default BasicPage

export const pageQuery = graphql`
  query($id: String!, $node_locale: String!) {
    contentfulBasicPage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      __typename
      title
      heroSection {
        ... on Node {
          ... on ContentfulPrimaryHeroCarousel {
            ...FragmentPrimaryHeroCarousel
          }
          ... on ContentfulSecondaryHero {
            ...FragmentSecondaryHero
          }
          ... on ContentfulTertiaryHero {
            ...FragmentTertiaryHero
          }
        }
      }
      bodySections {
        ... on Node {
          ... on ContentfulBaristasBlock {
            ...FragmentBaristasBlock
          }
          ... on ContentfulContentBannerNewsletter {
            ...FragmentContentBannerNewsletter
          }
          ... on ContentfulContentBannerPromo {
            ...FragmentContentBannerPromo
          }
          ... on ContentfulContentBannerTextAndImages {
            ...FragmentContentBannerTextAndImages
          }
          ... on ContentfulContentCarousel {
            ...FragmentContentCarousel
          }
          ... on ContentfulInstagramBlock {
            ...FragmentInstagramBlock
          }
          ... on ContentfulListingBlock {
            ...FragmentListingBlock
          }
          ... on ContentfulMultipleContentBlock {
            ...FragmentMultipleContentBlock
          }
          ... on ContentfulStackedBlocks {
            ...FragmentStackedBlocks
          }
          ... on ContentfulTextBlock {
            ...FragmentTextBlock
          }
          ... on ContentfulVotingBlock {
            ...FragmentVotingBlock
          }
          ... on ContentfulTip {
            ...FragmentTip
          }
          ... on ContentfulPaperformBlock {
            ...FragmentPaperformBlock
          }
          ... on ContentfulIframeBlock {
            ...FragmentIframeBlock
          }
          ... on ContentfulEventCarousel {
            __typename
            id
            title
            shortDescription {
              shortDescription
            }
            eventType
            cta {
              title
              externalUrl
              accessibleText
              content {
                ...FragmentNavigationElement
              }
            }
          }
        }
      }
      image {
        bynder: bynderAsset {
          fixed(width: 1200, toFormat: JPG, quality: 80) {
            ...QueryImageFixed
          }
        }
        local: localAsset {
          fixed(width: 1200, toFormat: JPG, quality: 80) {
            ...QueryImageFixed
          }
        }
      }
      metaTags {
        title
        description
        noindex
        nofollow
        keywords
        canonical
      }
    }

    contentfulSettings(locale: { eq: $node_locale }) {
      ...QuerySiteSettings
    }

    site {
      ...FragmentSite
    }
  }
`
